<template>
  <section>
    <!--<pm-Breadcrumb :home="home" :model="items_bread" />-->
    <!--<pm-Breadcrumb :home="home" :model="items_bread" />-->

    <div class="row">
      <div class="col-md-7">
        <h2 class="titulo-links-escolha">Disciplina</h2>
      </div>
      <div class="col-md-5 tbl-cadastro" v-if="situacaoAno && acesso.cadastrar">
        <pm-Button
          type="button"
          @click="registar()"
          label="CADASTRAR DISCIPLINA"
          icon="pi pi-external-link"
          title="CADASTRAR DISCIPLINA"
          class="p-button-success"
        ></pm-Button>
        <pm-Button
          type="button"
          @click="getAreaConhecimento()"
          label="ÁREA DE CONHECIMENTO"
          icon="pi pi-external-link"
          title="CADASTRAR DISCIPLINA"
          class="p-button-info mr-2"
        ></pm-Button>
      </div>
    </div>

    <AreaConhecimento :modal1="modal1"></AreaConhecimento>
    <div class="pm-card" v-if="acesso.visualizar">
      <pm-DataTable
        :value="disciplinaALL"
        dataKey="id"
        :rowHover="true"
        filterDisplay="menu"
        v-model:filters="filters"
        :globalFilterFields="['nome', 'carga_horaria']"
      >
        <template #header>
          <div class="flex justify-content-center align-items-center">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <pm-InputText
                v-model="pesquisa"
                @input="pesquisar"
                placeholder="Pesquisar Nome"
                style="height: 34px !important;"
              />
            </span>
          </div>
        </template>
        <pm-Column
          field="nome"
          sortable
          header="NOME"
          class="colunn-tbl"
        ></pm-Column>
        <pm-Column
          field="sigla"
          sortable
          header="SIGLA"
          class="colunn-tbl"
        ></pm-Column>
        <pm-Column
          field="carga_horaria"
          sortable
          header="CARGA HORÁRIA"
          class="colunn-tbl"
        ></pm-Column>
        <pm-Column
          field="id"
          headerStyle="width: 4rem; text-align: center"
          bodyStyle="text-align: center; overflow: visible;width: 357px;vertical-align:middle !important"
          class="colunn-tbl2"
        >
          <template #body="{ data }">
            <pm-Button
              type="button"
              @click="editarDisciplina(data.id)"
              icon="pi pi-pencil"
              label="Editar"
              title="Editar"
              v-if="situacaoAno && acesso.editar"
              class="p-button-sm btn-color;table_style"
            ></pm-Button>
            <pm-Button
              title="Apagar"
              type="button"
              v-if="situacaoAno && acesso.deletar"
              @click="deletarDisciplina(data.id)"
              label="Apagar"
              icon="pi pi-times-circle"
              class="p-button-danger p-button-sm btn-color;table_style"
              style="margin-left: 5px"
            ></pm-Button>
          </template>
        </pm-Column>
      </pm-DataTable>
      <div class="p-paginator p-component p-paginator-bottom">
        <div class="p-paginator-pages">
          <div v-for="n in links" :key="n">
            <button
              class="p-paginator-next p-paginator-element p-link"
              @click="paginas(n)"
              v-if="!n.active"
              style="border: 0px solid #ebebeb; border-radius: 0"
            >
              <p
                class="p-paginator-icon"
                v-html="n.label"
                style="margin-bottom: 0px !important"
              ></p>
            </button>
            <button
              class="p-paginator-page p-paginator-element p-link p-highlight"
              @click="paginas(n)"
              v-if="n.active"
              style="border-radius: 0"
            >
              {{ n.label }}
            </button>
          </div>
        </div>
        <selectPorPag qdt="20" @changePagina="changePagina"></selectPorPag>
      </div>
    </div>
  </section>

  <pm-Dialog
    :header="'Área de Conhecimento'"
    v-model:visible="modal2"
    modal
    :style="{ width: '20vw' }"
    :breakpoints="{ '960px': '80vw', '641px': '100vw' }"
  >
    <div class="col-12 grid justify-content-center mb-5">
      <label>Nome da Área de conhecimento</label>
      <pm-InputText v-model="areaConhecimento.nome" />
    </div>
    <div class="col-12 grid justify-content-between m-0">
      <pm-Button
        type="button"
        @click="voltarAreaConhecimento()"
        label="Voltar"
        icon="pi pi-external-link"
        class="p-button-danger p-button-sm"
      ></pm-Button>
      <pm-Button
        v-if="edicao === 1"
        type="button"
        @click="editarAreaConhecimento(areaConhecimento)"
        label="EDITAR"
        icon="pi pi-external-link"
        class="p-button-success p-button-sm"
      ></pm-Button>
      <pm-Button
        v-else
        type="button"
        @click="criaAreaConhecimento()"
        label="Cadastrar"
        icon="pi pi-external-link"
        class="p-button-success p-button-sm"
      ></pm-Button>
    </div>
  </pm-Dialog>

  <pm-Dialog
    modal
    :header="'Área de Conhecimento'"
    v-model:visible="modal1"
    :style="{ width: '70vw' }"
    :breakpoints="{ '960px': '80vw', '641px': '100vw' }"
  >
    <div class="col-12 grid justify-content-end m-0">
      <pm-Button
        type="button"
        @click="registrarAreaConhecimento()"
        label="CADASTRAR NOVA AREA"
        icon="pi pi-external-link"
        title="CADASTRAR NOVA AREA"
        class="p-button-success"
      ></pm-Button>
    </div>
    <pm-DataTable
      :value="areasConhecimento"
      dataKey="id"
      :rowHover="true"
      filterDisplay="menu"
      v-model:filters="filters"
      :globalFilterFields="['nome']"
    >
      <pm-Column
        field="nome"
        sortable
        header="NOME"
        class="colunn-tbl"
      ></pm-Column>
      <pm-Column
        field="id"
        headerStyle="width: 4rem; text-align: center"
        bodyStyle="text-align: center; overflow: visible;width: 357px;vertical-align:middle !important"
        class="colunn-tbl2"
      >
        <template #body="{ data }">
          <pm-Button
            type="button"
            @click="modalEditarAreaConhecimento(data)"
            icon="pi pi-pencil"
            label="Editar"
            title="Editar"
            v-if="situacaoAno && acesso.editar"
            class="p-button-sm btn-color;table_style"
          ></pm-Button>
          <pm-Button
            title="Apagar"
            type="button"
            v-if="situacaoAno && acesso.deletar"
            @click="deletarAreaConhecimento(data.id)"
            label="Apagar"
            icon="pi pi-times-circle"
            class="p-button-danger p-button-sm btn-color;table_style"
            style="margin-left: 5px"
          ></pm-Button>
        </template>
      </pm-Column>
    </pm-DataTable>
  </pm-Dialog>
</template>

<script>
import axios from "axios";
import { defineComponent } from "vue";
import { DisciplinaEscolar } from "@/class/disciplina.js";
import { Verificar } from "@/class/verificar.js";
import Util from "@/utils/rota";
import { AreaConhecimento } from "@/class/areaConhecimento.js";

import AreasConhecimento from "@/components/area_conhecimento/AreaConhecimento.vue";
import selectPorPag from "@/components/selectPorPag.vue";
import pagina from "@/class/pagina";

export default defineComponent({
  components: {
    selectPorPag,
    AreasConhecimento
  },
  data() {
    const columns = [
      { key: "nome", label: "Nome", sortable: true },
      { key: "carga_horaria", label: "Carga Horária", sortable: true },
      { key: "st", label: "Status" },
      { key: "id", label: "Ações" },
    ];
    return {
      funcao: 0,
      filters: "",
      home: { icon: "pi pi-home", to: "#" },
      items_bread: [{ label: "Disciplinas" }],
      situacaoAno: 1,
      filter: "",
      disciplinaALL: [],
      columns,
      perPage: 20,
      currentPage: 1,
      status: [],
      pesquisa: null,
      links: [],
      acesso: {
        visualizar: 1,
        cadastrar: 1,
        editar: 1,
        deletar: 1,
      },
      modal1: false,
      modal2: false,
      edicao: 0,
      areasConhecimento: [],
      areaConhecimento: {
        nome: "",
      },
    };
  },
  computed: {
    pages() {
      return this.perPage && this.perPage !== "0"
        ? Math.ceil(this.disciplinaALL.length / this.perPage)
        : this.disciplinaALL.length;
    },
  },
  async beforeMount() {
    this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));
    await this.logado();
    await this.listarTudo();
    const acesso = await Verificar.AcessoLocal(6);
    this.acesso.visualizar = acesso.visualizar;
    this.acesso.cadastrar = acesso.cadastrar;
    this.acesso.editar = acesso.editar;
    this.acesso.deletar = acesso.deletar;
  },
  methods: {
    async editarAreaConhecimento(area) {
      try {
        const areaConhecimento = await AreaConhecimento.alterar(area);

        if (areaConhecimento.status === 200) {
          this.$vaToast.init({
            message: "Editado com sucesso!",
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 2500,
            color: "success",
            fullWidth: false,
          });
          this.edicao = 0;
          this.areaConhecimento.nome = "";
          this.areasConhecimento = [];
          this.getAreaConhecimento();
        }
      } catch (error) {
        console.log(error);
      }
    },
    modalEditarAreaConhecimento(area) {
      this.areaConhecimento = area;
      this.modal1 = false;
      this.modal2 = true;
      this.edicao = 1;
    },
    async deletarAreaConhecimento(id) {
      try {
        const areaConhecimento = await AreaConhecimento.remover(id);
        if (areaConhecimento.status === 200) {
          this.$vaToast.init({
            message: "Deletado com sucesso!",
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 2500,
            color: "success",
            fullWidth: false,
          });
          this.areasConhecimento = [];
          this.getAreaConhecimento();
        }
      } catch (error) {}
    },
    async criaAreaConhecimento() {
      try {
        const areaConhecimento = await AreaConhecimento.cadastrar(
          this.areaConhecimento
        );

        if (areaConhecimento.status === 201) {
          this.$vaToast.init({
            message: "Cadastrado com sucesso!",
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 2500,
            color: "success",
            fullWidth: false,
          });
          this.areasConhecimento = [];
          this.areaConhecimento.nome = "";
          this.getAreaConhecimento();
        }
      } catch (error) {
        if (error.response.status === 422) {
          this.$vaToast.init({
            message: "Esse nome já existe.",
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 3500,
            color: "danger",
            fullWidth: false,
          });
        } else {
          this.$vaToast.init({
            message: "Ocorreu um problema!",
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 3500,
            color: "danger",
            fullWidth: false,
          });
        }
      }
    },
    async getAreaConhecimento() {
      if (this.areasConhecimento.length === 0) {
        const areasConhecimento = await AreaConhecimento.obtemTodos();
        this.areasConhecimento = areasConhecimento.data;
      }
      this.modal2 = false;
      this.modal1 = true;
    },
    registrarAreaConhecimento() {
      this.modal1 = false;
      this.modal2 = true;
    },
    voltarAreaConhecimento() {
      this.modal2 = false;
      this.modal1 = true;
      this.edicao = 0;
    },
    changePagina(pg){
      pagina.routePg = "&pg="+pg
      pagina.pg = pg
      this.listarTudo()
    },
    async logado() {
      const token = sessionStorage.getItem("token");
      const util = new Util();
      const data = await util.WhoIam(token);
      this.funcao = data.funcao;
    },
    async pesquisar() {
      try {
        if (this.pesquisa != null && this.pesquisa != "") {
          const data = await DisciplinaEscolar.pesqDisciplinaCompleto(
            this.pesquisa
          );
          this.disciplinaALL = [];
          this.links = [];

          let index = 0;
          for (const el of data.data) {
            let st = true;

            if (el.status == 1) {
              st = true;
            } else {
              st = false;
            }
            if (el.id != 1 && el.id != 2) {
              const novo = {
                id: el.id,
                nome: el.nome,
                status: el.status,
                st: {
                  index: index,
                  id: el.id,
                },
              };

              this.status.push(st);
              this.disciplinaALL.push(novo);
              index++;
            }
          }
          this.disciplinaALL.reverse();
        } else {
          this.listarTudo();
        }
      } catch (e) {
        // console.log(e)
      }
    },

    async paginas(n) {
      const routePg = pagina.routePg;
      if (n.url != null) {
        if (!n.active) {
          const res = await axios.get(n.url+routePg);
          this.disciplinaALL = [];
          //this.disciplinaALL = res.data.data;
          this.links = res.data.links;
          let index = 0;
          for (const el of res.data.data) {
            let st = true;

            if (el.status == 1) {
              st = true;
            } else {
              st = false;
            }
            if (el.id != 1 && el.id != 2) {
              const novo = {
                id: el.id,
                nome: el.nome,
                carga_horaria: el.carga_horaria,
                status: el.status,
                st: {
                  index: index,
                  id: el.id,
                },
              };

              const sst = {
                index: index,
                id: el.id,
              };
              this.status.push(st);
              this.disciplinaALL.push(novo);
              index++;
            }
          }
          this.disciplinaALL.reverse();
        }
      }
    },
    editarDisciplina(id) {
      if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
        this.$router.push({ name: "disciplinaEditar", params: { id: id } });
      } else {
        this.$router.push({
          name: "coordenacao-disciplina-edit",
          params: { id: id },
        });
      }
    },
    registar() {
      if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
        this.$router.push({ name: "disciplinaCadastro" });
      } else {
        this.$router.push({ name: "coordenacao-disciplina-add" });
      }
    },

    async deletarDisciplina(id) {
      try {
        if (window.confirm("Você realmente deseja apagar a disciplina?")) {
          const data = await DisciplinaEscolar.remover(id);
          this.$vaToast.init({
            message: "Excluído com sucesso!",
            iconClass: "fa-star-o",
            position: "top-right",
            color: "success",
            duration: 2500,
            fullWidth: false,
          });
          this.disciplinaALL = [];
          this.listarTudo();
        }
      } catch (e) {
        const a = e.response.data.error;
        this.$vaToast.init({
          message: a,
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 4000,
          fullWidth: false,
          color: "danger",
        });
        return false;
      }
    },
    async mudarStatus(id, index) {
      try {
        let st = 0;
        if (this.status[index] == true) {
          st = 0;
        } else {
          st = 1;
        }

        const payload = {
          id: id,
          status: st,
        };
        const data = await DisciplinaEscolar.alterarStatus(payload);
        this.disciplinaALL = [];
        this.listarTudo();
      } catch (e) {}
    },
    async listarTudo() {
      try {
        this.disciplinaALL = []
        //let data = await DisciplinaEscolar.obtemTodos();
        const data = await DisciplinaEscolar.obtemTodosPag(this.currentPage);
        this.links = data.data.links;
        let index = 0;
        for (const el of data.data.data) {
          let st = true;

          if (el.status == 1) {
            st = true;
          } else {
            st = false;
          }
          if (el.id != 1 && el.id != 2) {
            const novo = {
              id: el.id,
              nome: el.nome,
              sigla: el.sigla,
              carga_horaria: el.carga_horaria + " Horas",
              sigla: el.sigla,
              status: el.status,
              st: {
                index: index,
                id: el.id,
              },
            };

            const sst = {
              index: index,
              id: el.id,
            };

            this.status.push(st);
            this.disciplinaALL.push(novo);
            index++;
          }
        }
        this.disciplinaALL.reverse();
      } catch (e) {}
    },
  },
});
</script>

<style lang="scss" scoped>
.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}
</style>
